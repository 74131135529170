import { create } from 'zustand';


const useAgentStore = create((set) => ({
    agentType: null,
    agentId: null,
  
    setAgentType: (agentData) => {
      if (agentData.id_agente) {
        set({ agentType: 'Agente', agentId: agentData.id_agente });
      } else if (agentData.id_subconcesionario) {
        set({ agentType: 'SubConcesionario', agentId: agentData.id_subconcesionario });
      } else {
        set({ agentType: 'Concesionario', agentId: agentData.id_concesionario });
      }
    },
  }));
  
  export default useAgentStore;