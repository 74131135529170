import {
  Box,
  Card,
  CardContent,
  Divider,
  Modal,
  alpha,
  Typography,
  useTheme,
  TextField,
  Button,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import { AuthContext } from "src/contexts/FirebaseAuthContext";
import InfoIcon from "@material-ui/icons/Info";

import { uploadFileToS3, subtractDays } from "src/utils/core";
import { useForm } from "react-hook-form";
import { useState, useContext } from "react";
import useAgentStore from "src/hooks/UseStore";

const CustomCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  padding: "30px",
  "&:last-child": {
    paddingBottom: "30px",
  },
  overflow: "hidden",
  height: "100%",
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  marginBottom: "10rem",
}));

const VoucherModal = ({ open, handleCloseModal, hideVoucherButton }) => {
  const { user } = useContext(AuthContext);
  const [openDialogVoucherConfirmation, setOpenDialogVoucherConfirmation] =
    useState(false);

  const [isSendingForm, setIsSendingForm] = useState(false);
  const [voucherValue, setVoucherValue] = useState();

  const agentType = useAgentStore((state) => state.agentType);

  let id;
  if (agentType === "Concesionario") {
    id = user?.id_concesionario;
  } else if (agentType === "SubConcesionario") {
    id = user?.id_subconcesionario;
  } else if (agentType === "Agente") {
    id = user?.id_agente;
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: isMobile ? "95%" : "40%",
    transform: "translate(-50%, -50%)",
    bgcolor: theme.palette.background.paper,
    border: "1px solid #000",
    overflow: "hidden",
  };

  const formInputWrapper = {
    display: "flex",
    flexDirection: "row",
  };

  const scrollBoxStyle = {
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(80vh - 100px)",
    overflow: "auto",
    scrollbarGutter: "stable",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#B8B8B8",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#808080",
      },
    },
  };

  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => onSubmitHandler(data);

  const banks = {
    1: "Banpais",
    2: "Bac",
  };
  const [selectedDate, setSelectedDate] = useState(subtractDays(new Date(), 0));
  const [showVoucherResume, setShowVoucherResume] = useState(false);
  const [selectedBank, setSelectedBank] = useState(0);

  const [file, setFile] = useState(null);
  const [voucherData, setVoucherData] = useState({
    deposit_date: new Date().toDateString(),
    value: 0,
    bank: 0,
    number: "",
    description: "",
    document_url: "",
  });

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    setFile(newFile);
  };

  const onSubmitHandler = async (dataForm) => {
    try {
      let s3FileUrl = "";
      if (selectedBank == 0) {
        alert("Debes seleccionar un banco!");
        return;
      }

      if (selectedDate == "") {
        alert("Debes seleccionar una fecha!");
        return;
      }

      if(dataForm.voucher == ""){
        alert("Debes ingresar el número de comprobante!");
        return;
      }

      if(dataForm.value == ""){
        alert("Debes ingresar el valor del comprobante!");
        return;
      }

      if(dataForm.value < 250){
        alert("El valor mínimo es de 250L");
        return;
      }      

      if (!file) {
        alert("Debes seleccionar un archivo!");
        return;
      }

      if (file) {
        const maxFileSize = 5 * 1024 * 1024;
        if (file.size >= maxFileSize) {
          setIsSendingForm(false);
          alert("El peso del archivo no puede exceder 5MB");
          return;
        }

        setIsSendingForm(true);
        await uploadFileToS3(file, (status) => {
          console.log("status => 108 => ", status);
          s3FileUrl = status;
        });
      }

      if( s3FileUrl === "" ){
        alert("Error al subir el archivo!");
        return;
      }

      const data = {
        date:selectedDate,
        file: s3FileUrl,
        bank: Number(dataForm.bank),
        voucher: dataForm.voucher,
        value: Number(dataForm.value),
        observations: dataForm.observations,
        userId: id,
      };

      const response = await fetch("/api/voucher", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      setIsSendingForm(false);

      if (result.success == false) {
        alert(result.message);
      }

      if (result.success) {
        if(!isMobile){
          hideVoucherButton();
        }
        setOpenDialogVoucherConfirmation(true);
        setVoucherData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderTooltip = (message) => {
    return (
      <Tooltip arrow placement="top" title={message}>
        <InfoIcon color="action" />
      </Tooltip>
    );
  };

  const handleCloseVoucherConfirmation = () => {
    setOpenDialogVoucherConfirmation(false);
  };

  return (
    <>
      {!openDialogVoucherConfirmation && (
        <Modal
          disableEscapeKeyDown
          open={open}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <Card sx={style}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                padding: "15px 15px",
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
              }}
            >
              <Typography variant="h4" margin="auto">
                Comprobante bancario
              </Typography>

              <IconButton
                onClick={() => handleCloseModal(false)}
                sx={{ paddingY: 0 }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
            <CustomCardContent>
              {showVoucherResume ? (
                <Box sx={scrollBoxStyle}>
                  <Typography variant="h5" sx={{ my: 2 }}>
                    Su comprobante fue cargado con éxito. Espera unos minutos
                    mientras validamos la acreditación.
                  </Typography>

                  <Divider sx={{ my: 2 }} />
                  <Typography>Banco: {banks[voucherData.bank]}</Typography>
                  <Typography>Fecha en que realizó el depósito: {voucherData.date}</Typography>
                  <Typography>N Referencia: {voucherData.voucher}</Typography>
                  <Typography>Valor: {voucherData.value}</Typography>
                  <Typography>
                    Observaciones: {voucherData.observations}
                  </Typography>
                  <Divider />
                  <img
                    src={voucherData.file}
                    width={"50%"}
                    height={"50%"}
                    alt="Voucher image"
                  />

                  <Button
                    sx={{
                      mt: 3,
                    }}
                    color="primary"
                    size="large"
                    fullWidth
                    type="submit"
                    variant="contained"
                    onClick={() => handleCloseModal()}
                  >
                    Finalizar
                  </Button>
                </Box>
              ) : (
                <Box sx={scrollBoxStyle}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div
                      className="input-wrapper"
                      sx={InputWrapper}
                      fullWidth={true}
                    >
                      <Typography>Banco</Typography>
                      <Box sx={formInputWrapper}>
                        <Select
                          fullWidth={true}
                          width={"95%"}
                          {...register("bank")}
                          size="small"
                          value={selectedBank}
                          onChange={(e) => setSelectedBank(e.target.value)}
                        >
                          <MenuItem value="0">Seleccione un banco</MenuItem>
                          <MenuItem value="1">Banpais</MenuItem>
                          <MenuItem value="2">Bac</MenuItem>
                        </Select>
                        {renderTooltip("Banco donde se realizó el depósito")}
                      </Box>
                    </div>

                    <div className="input-wrapper">
                      <Typography>Fecha en que realizó el depósito</Typography>
                      <Box sx={formInputWrapper}>
                        <Select
                          fullWidth={true}
                          width={"95%"}
                          {...register("date")}
                          size="small"
                          value={selectedDate}
                          placeholder={selectedDate}
                          onChange={(e) => {
                            console.log("e.target.value => ", e.target.value);
                            setSelectedDate(e.target.value);
                          }}
                        >
                          <MenuItem value={subtractDays(new Date(), 0)}>
                            {subtractDays(new Date(), 0)}
                          </MenuItem>
                          <MenuItem value={subtractDays(new Date(), 1)}>
                            {subtractDays(new Date(), 1)}
                          </MenuItem>
                          <MenuItem value={subtractDays(new Date(), 2)}>
                            {subtractDays(new Date(), 2)}
                          </MenuItem>
                        </Select>
                        {renderTooltip("Fecha en que realizó el depósito")}
                      </Box>
                    </div>

                    <div className="input-wrapper">
                      <Typography>N Referencia</Typography>
                      <Box sx={formInputWrapper}>
                        <TextField
                          fullWidth={true}
                          width={"95%"}
                          size="small"
                          type="number"
                          variant="outlined"
                          placeholder="N Referencia"
                          sx={{
                            " & input::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                            },
                          }}
                          {...register("voucher")}
                        />
                        {renderTooltip("Número de referencia del depósito")}
                      </Box>
                    </div>

                    <div className="input-wrapper">
                      <Typography>Valor</Typography>
                      <Box sx={formInputWrapper}>
                        <TextField
                          fullWidth={true}
                          size="small"
                          type="text"
                          placeholder="Valor"
                          sx={{
                            " & input::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                            },
                          }}
                          value={voucherValue}
                          {...register("value")}
                          onChange={
                            (e) => {
                              const value = e.target.value;
                              let replacedValue = value.replace(",", ".");
                              const periodPosition = replacedValue.indexOf('.');

                              if(periodPosition > -1){
                                const newValue = replacedValue.substring(0, periodPosition+3)
                                setVoucherValue(newValue);
                                return;
                              }
                              setVoucherValue(replacedValue);
                            }
                          }
                        />
                        {renderTooltip("Valor por el cual realizó el depósito")}
                      </Box>
                    </div>

                    <div className="input-wrapper">
                      <Typography>Observaciones</Typography>
                      <Box sx={formInputWrapper}>
                        <TextField
                          fullWidth={true}
                          size="small"
                          type="text"
                          variant="outlined"
                          placeholder="Observaciones"
                          sx={{
                            " & input::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                            },
                          }}
                          {...register("observations")}
                        />
                        {renderTooltip(
                          "Observaciones sobre el depósito bancario"
                        )}
                      </Box>
                    </div>

                    <div className="input-wrapper">
                      <Typography>Archivo</Typography>
                      <Box sx={formInputWrapper}>
                        <input
                          name="file"
                          type="file"
                          onChange={handleFileChange}
                          accept=".jpg,.jpeg,.png,.gif,.heic"
                        />
                      </Box>
                    </div>

                    <Button
                      sx={{
                        mt: 3,
                      }}
                      color="primary"
                      size="large"
                      fullWidth
                      type="submit"
                      variant="contained"
                      disabled={isSendingForm ? true : false}
                    >
                      Enviar
                    </Button>
                  </form>
                </Box>
              )}
            </CustomCardContent>
          </Card>
        </Modal>
      )}
      {openDialogVoucherConfirmation && (
        <Dialog open={open} onClose={handleCloseVoucherConfirmation}>
          <DialogTitle
            sx={{
              backgroundColor: "#eeeeee",
            }}
          ></DialogTitle>
          <DialogContent
            sx={{
              backgroundColor: "#eeeeee",
            }}
          >
            <DialogContentText>
              <Typography variant="h5" sx={{ my: 2, textAlign: "center" }}>
                Su comprobante fue cargado con éxito.
              </Typography>
              <Typography variant="h5" sx={{ my: 2, textAlign: "center" }}>
                Espera unos minutos mientras validamos la acreditación.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: "#eeeeee",
            }}
          >
            <Button onClick={() => {
              setOpenDialogVoucherConfirmation(false);
              handleCloseModal();
            }}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default VoucherModal;
