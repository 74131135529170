import { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import firebase from 'src/utils/firebase';
import PropTypes from 'prop-types';
import useAgentStore from 'src/hooks/UseStore';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

export const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  signInWithEmailAndPassword: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const setAgentType = useAgentStore((state) => state.setAgentType);


  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user) => {

        if (user) {
          const email = user?.email;
          const response = await axios.get(`/api/agents/getagents`, { params: { email} });
          const agente = await response.data;
                
            if(agente.hasOwnProperty('id_agente')) {
              setAgentType(agente);
            } else if(agente.hasOwnProperty('id_subconcesionario')) {
              setAgentType(agente);
            } else if(agente.hasOwnProperty('id_concesionario')) {
              setAgentType(agente);
            } else {
              console.log("Type of agent not found")
            }

          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: agente,
            }
          });
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      }),
    [dispatch]
  );

  const signInWithEmailAndPassword = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const logout = async () => {
    await firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        signInWithEmailAndPassword,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
