import { Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Sidebar from "./Sidebar";
import MobileNavigation from "./MobileNavigation"
import Header from "./Header";
import Footer from "src/components/Footer";
import { useContext, useEffect, useState } from "react";
import { SidebarContext } from "src/contexts/SidebarContext";
import useAgentStore from "src/hooks/UseStore";
import { AuthContext } from "src/contexts/FirebaseAuthContext";

const ExtendedSidebarLayout = ({ children }) => {
  const [hasPendingVoucher, setHasPendingVoucher] = useState(false);
  const { sidebarToggle } = useContext(SidebarContext);
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const agentType = useAgentStore((state) => state.agentType);

  let id;
  if (agentType === "Concesionario") {
    id = user?.id_concesionario;
  } else if (agentType === "SubConcesionario") {
    id = user?.id_subconcesionario;
  } else if (agentType === "Agente") {
    id = user?.id_agente;
  }

  useEffect(() => {
    fetchUserVouchers();  
  }, [])

  const fetchUserVouchers = async () => {
    const response = await fetch(`/api/voucher?userId=${id}`);
    const result = await response.json();
    const pendingVoucher =
      result.vouchers && result.vouchers.length > 0
        ? result.vouchers.find((e) => e.status == 0)
        : null;
        
    if (pendingVoucher) {
      setHasPendingVoucher(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          
          height: "100%",
        }}
      >
        <Header hasPendingVoucher={hasPendingVoucher} setHasPendingVoucher={setHasPendingVoucher}/>
        <Sidebar/>
        <Box
          marginLeft={sidebarToggle ? theme.sidebar.width : 0}
          
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`
        
          }}
        >
          <Box display="block">{children}</Box>
          <MobileNavigation hasPendingVoucher={hasPendingVoucher}/>
          {/* <Footer /> */}
        </Box>
      </Box>
    </>
  );
};

ExtendedSidebarLayout.propTypes = {
  children: PropTypes.node,
};

export default ExtendedSidebarLayout;
