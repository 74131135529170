import {
  addDays,
  addHours,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { zonedTimeToUtc } from "date-fns-tz";
import "dayjs/locale/es";
dayjs.extend(utc);
dayjs.extend(timezone);

export const predefinedRanges = [
  {
    label: "Hoy",
    value: [
      zonedTimeToUtc(new Date(), "America/El_Salvador"),
      zonedTimeToUtc(new Date(), "America/El_Salvador"),
    ],
  },
  {
    label: "Ayer",
    value: [
      addDays(addHours(new Date(), -6), -1),
      addDays(addHours(new Date(), -6), -1),
    ],
  },
  {
    label: "Esta semana",
    value: [
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      endOfWeek(new Date(), { weekStartsOn: 1 }),
    ],
  },
  {
    label: "Semana pasada",
    value: [
      startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
      endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
    ],
  },
  {
    label: "Este mes",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: "Mes pasado",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
  },
  {
    label: "Este año",
    value: [startOfYear(new Date()), endOfYear(new Date())],
  },
];

export const uploadFileToS3 = async (file, finishCallBack) => {
  const S3_BUCKET = process.env.NEXT_PUBLIC_VOUCHERS_S3_BUCKET;
  const REGION = process.env.NEXT_PUBLIC_VOUCHERS_S3_REGION;
  const s3Url = "https://sivarbetkyc.s3.eu-north-1.amazonaws.com/";
  const s3Folder = "vouchers/";

  const s3 = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: process.env.NEXT_PUBLIC_VOUCHERS_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.NEXT_PUBLIC_VOUCHERS_S3_ACCESS_KEY,
    },
  });

  const time = new Date().getTime();
  const uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  const key = `${s3Folder}${time}-${uniqueId}-${file.name}`;

  const command = new PutObjectCommand({
    Bucket: S3_BUCKET,
    Key: key,
    Body: file,
    ACL: 'public-read',
  });

  const response = await s3.send(command);
  if( response.$metadata.httpStatusCode === 200 ){
    finishCallBack(`${s3Url}${key}`);
  }
  else{
    finishCallBack(null);
  }
};

export const subtractDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  return (
    newDate.getFullYear() +
    "-" +
    (newDate.getMonth() + 1) +
    "-" +
    newDate.getDate()
  ).toLocaleString();
};

export const formatDate = (date, hours = false) => {

  if(date !== "" && date !== null && date !== undefined){
    const timeZone = "America/El_Salvador";
    const value = dayjs.tz(date,timeZone).set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)
    .locale("es")
    .format("DD MMM YYYY");
    return value;
  }
  return "";
}

export const validateIdsForVoucherModuleProd = (id) => {
  return [10101010].includes(id);
}

export const isSameDate = (date1, date2) => date1.toISOString() === date2.toISOString();

export const renderDateRangeLabel = (value) => {
  if (!value || value.length !== 2) return "Rango de fechas";
  const [startDate, endDate] = value;

  for (const range of predefinedRanges) {
    const [rangeStart, rangeEnd] = range.value;
    if (
      (isSameDate(startOfDay(startDate), rangeStart) &&
        isSameDate(endOfDay(endDate), rangeEnd)) ||
      (isSameDate(
        startDate,
        zonedTimeToUtc(rangeStart, "America/El_Salvador")
      ) &&
        isSameDate(endDate, zonedTimeToUtc(rangeEnd, "America/El_Salvador")))
    ) {
      return range.label;
    }
  }

  return `${startDate.toLocaleDateString()} a ${endDate.toLocaleDateString()}`;
};

export const handleChangeDateRange = (event, setDateRange) => {
  const [startDate, endDate] = event;
  const timeZone = "America/El_Salvador";

  const newStartDate = zonedTimeToUtc(
    startOfDay(startDate),
    timeZone
  ).toISOString();
  const newEndDate = zonedTimeToUtc(
    endOfDay(endDate),
    timeZone
  ).toISOString();

  setDateRange([newStartDate, newEndDate]);
};